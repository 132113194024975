import React from "react";
import DefautLayout from "../layouts/default";
import * as AuthorityPage from "../components/screens/authority";
import "../assets/sass/index.scss";

export default function Authority() {
  return (
    <DefautLayout title="Für Behörden">
      <div id="authority">
        <AuthorityPage.Header />
        <AuthorityPage.Introduction />
        <AuthorityPage.Break />
        <AuthorityPage.Success />
        <AuthorityPage.Teaser />
        <AuthorityPage.Pros />
        <AuthorityPage.Time />
        <AuthorityPage.Process />
      </div>
    </DefautLayout>
  );
}
