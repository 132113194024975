import React from "react";
import IconPerson from "../../../assets/img/icons/icon-person.svg";
import IconPrototype from "../../../assets/img/icons/icon-prototype.svg";
import IconCode from "../../../assets/img/icons/icon-code.svg";

let successes = [
  {
    icon: IconPerson,
    alt: "person",
    align: "left",
    excerpt:
      "Interdisziplinäre Teams aus Digitaltalenten experimentieren in einem geschützten Umfeld.",
  },
  {
    icon: IconPrototype,
    alt: "prototyp",
    align: "left",
    excerpt:
      "Iterative Entwicklung und Validierung von Lösungskonzepten. Ein Prototyp erweckt die Idee zum Leben.",
  },
  {
    icon: IconCode,
    alt: "code",
    align: "left",
    excerpt: "Enge Zusammenarbeit mit jungen Talenten und IT-Fachkräften.",
  },
];
export default function Success() {
  return (
    <section  id="success">
      <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content">
        <div className="col-12 headline-1">Unser Erfolgsrezept</div>
        <div className="col-12 lg:col-6">
          {successes.map((success, index) => (
            <div key={index} className="grid grid-gap-0">
              <div className="col-12 lg:col-3 xs:pt-30 xs:pb-0 lg:pb-30 lg:pt-0">
                <img width="80" height="80" src={success.icon} alt={success.alt} />
              </div>
              <div className="col-12 lg:col-9">
                <p className="body-1 fw-normal">{success.excerpt}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 lg:col-6 body-1">
          So eröffnen sich unzählige Möglichkeiten. Interne Vorgänge oder
          Angebote an Bürger können weiterentwickelt oder gänzlich neu gedacht
          werden.
          <br /> Wo könnten neue Ansätze das Leben von Ihnen, Ihren Kollegen und Kolleginnen
          und/oder der Bürger und Bürgerinnen erleichtern?
        </div>
      </div>
    </section>
  );
}
