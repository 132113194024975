import React from "react";
import SharedHeader from "../../shared/header";
import teaser from "../../../assets/img/components/teaser/teaser.webp";

export default function Teaser() {
  return (
    <section  id="teaser">
      <SharedHeader
        image={teaser}
        classNameImage="wallpaper-image"
        extraClassName="teaser py-0"
        align="left"
        titlesize="headline-1"
        titlecolor="black"
        title="Die Zukunft gehört uns - lasst sie uns gemeinsam gestalten!"
        textsize="body-1 pt-50"
     
      />
    </section>
  );
}
