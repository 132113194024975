import React from "react";
import ColumnText from "../../shared/columnText";

export default function Introduction() {
  return (
    <section  id="introduction">
      <ColumnText
        nrColumns="2"
        textLeftStyle="display-2 tc-blue"
        textLeft="JETZT ZU DIGITALEN PIONIEREN WERDEN!"
        textRight={
          <>
            <p>
              Initiiert durch das Bayerische Staatsministerium für Digitales, löst das Digitalschmiede Programm von byte − Bayerische Agentur für Digitales GmbH echte Herausforderungen aus der öffentlichen Verwaltung.
            </p>
            <p className="pt-20">
              Die Digitalschmiede Bayern ist ein
              <strong> 3-monatiges Fellowship-Programm</strong>, in dem interdisziplinäre und von Verwaltungsmitarbeitenden begleitete Projekt-Teams befähigt werden, digitale Prototypen für die bayerischen Bürgerinnen und Bürger sowie die Behörden zu bauen.
            </p><br></br>
            <p>Nach 4 erfolgreichen Batches mit zahlreichen Projekten aus der Bayerischen Verwaltung geht die Digitalschmiede Bayern in die nächste Runde. Wir haben die Zeit genutzt, um das Programm auf Basis unserer Erfahrungen aus den  bisherigen Batches zu optimieren und anzupassen.
            Startschuss des Programms ist der 05. August 2024, welches nach knapp drei Monaten am 31. Oktober 2024 seinen Abschluss finden wird.</p>
          </>
        }
      />
    </section>
  );
}
