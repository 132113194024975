import React from "react";
import ColumnText from "../../shared/columnText";
import { PixelsPros } from "../../../assets/img/icons";

export default function Pros() {
  return (
    <section  id="success">
      <ColumnText
        nrColumns="2"
        bigTitle={
          <div className="grid">
            <div className="col-12 lg:col-4 p-0">
              Die Vorteile auf einen Blick
            </div>

            <div className="lg:col-8 py-0 md:hidden sm:hidden xs:hidden xxs:hidden">
              <img src={PixelsPros} alt="pixels" />
            </div>
          </div>
        }
        textLeftStyle="body-1"
        listLeft={true}
        textLeft={
          <>
            <li>
              Interdisziplinäres Team aus den Bereichen Produktmanagement, Design und Software-Entwicklung
            </li>
            <li>12 gemeinsame Wochen mit Projektarbeit und Weiterbildung</li>
            <li>
              1-2 Mitarbeiterinnen und Mitarbeiter aus der Verwaltung unterstützen und begleiten das Team
            </li>
          </>
        }
        listRight={true}
        textRight={
          <>
            <li>Prototypisch realisierte Lösung zu Ihrer Herausforderung</li>
            <li>Von- und miteinander lernen</li>
            <li>Agile und nutzerzentrierte Ansätze anwenden</li>
            <li>Vernetzung mit Fellows und Digitalisierungsenthusiasten</li>
          </>
        }
      />
    </section>
  );
}
