import React from "react";
import { Link } from "gatsby";
import Template from "../../../assets/downloads/Vorlage_Projektsteckbrief_Themenpaten_byte.pdf";

export default function Break() {
  return (

    <section id="breakAuthorities">
        <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content">
          <div className="col-12 ">
            <h3 className="display-2 tc-blue">So reichen Sie Ihre Herausforderung ein:</h3>
          </div>
            <div className="col-12 body-1">
              <div className="note bc-lightgrey p-50">
                <p>
                Für dieses Jahr ist die Bewerbungsphase der Digitalschmiede Bayern abgeschlossen. Aber wir starten im nächsten Jahr in eine neue Runde!
Haben Sie noch Fragen? Dann kontaktieren Sie uns gerne unter <a className="link" href="mailto:digitalschmiede@byte.bayern">digitalschmiede@byte.bayern</a>.
                </p>
              </div>
            </div>
        </div>        
    </section>
  );
}