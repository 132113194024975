import React from "react";
import ColumnText from "../../shared/columnText";
import LineBreak from "../../shared/lineBreak";

export default function Time() {
  return (
    <section  id="time">
      <LineBreak />
      <ColumnText
        nrColumns="2"
        bigTitle={
          <>
        So viel Zeit müssen Sie als Themenpaten aus den bayerischen Ministerien mitbringen
          </>
        }
        textLeftStyle="body-1"
        listLeft={true}
        textLeftTitle="Verpflichtend:"
        textLeft={
          <>
            <li>Kick-Off mit dem Team <nobr>(einmalig 2-3 Tage)</nobr></li>
            <li>Wöchentlicher Austausch mit dem Team und anderen Themenpatinnen (5 Std. / Woche)</li>
            <li>Austausch mit dem Team, Vernetzung, Feedback (nach Bedarf)</li>
            <li>Abschlussveranstaltung (2 Std.)</li>
          </>
        }
        listRight={true}
        textRightTitle="Optional:"
        textRight={
          <>
            <li>Gemeinsame Projektarbeit (nach Möglichkeit)</li>
            <li>
              Teilnahme an Weiterbildungsangeboten, z. B. offene Workshops oder Vorträge
              <nobr> (1-2 Std. / Woche)</nobr>
            </li>
            <li>
             Veranstaltungen wie bspw. Besuche bei Firmen
            </li>
          </>
        }
      />
    </section>
  );
}
