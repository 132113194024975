import React from "react";
import processImage from "../../../assets/img/components/process/workingColab.webp";
import ApplicationProcess from "../../shared/applicationProcess";

export default function Process() {
  return (
    <section  id="process">
      <ApplicationProcess
        steps={[
          "Einreichen der Herausforderung oder Idee aus einem der bayerischen Ministerien",
          "Kennenlerngespräch mit uns über Ihre Herausforderung und persönlichen Vorstellung",
          "Auswahl der eingereichten Themen durch uns",
          "Zusage erhalten",
          "Teilnahme am Themendefinitionsworkshop",
          "Einführungsveranstaltung",
          "Kick-Off mit den Fellows",
        ]}
        title="So werden Sie zum Themenpaten:"
        buttontext="Herausforderung einreichen!"
        buttonGridTemplateAreas={`'vred . .''. icon text'`}
        buttonIconColor="yellow"
        buttonTextBackgroundColor="black"
        buttonTextColor="white"
        image={processImage}
        link="/authority/#breakAuthorities"
      />
    </section>
  );
}
